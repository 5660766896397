<template>
  <div id="contact-us" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
    <v-container class="fill-height">
      <v-row align="center" class="text-center">
        <!-- 좌 -->
        <v-col cols="12" md="7" class="left">
          <h2>Contact Us</h2>
          <p>언제든지 문의해 주세요.</p>
        </v-col>

        <!-- 우 -->
        <v-col cols="12" md="5" class="right">
          <v-form ref="contactUsForm" class="right px-4">
            <!-- 1) 회사명 -->
            <v-row align="center">
              <v-col cols="3" md="2" class="text-left">
                <h4>회사명</h4>
              </v-col>

              <v-col cols="9" md="7">
                <v-text-field
                  v-model="companyName"
                  outlined
                  dense
                  hide-details
                  maxLength="20"
                />
              </v-col>
            </v-row>

            <!-- 2) 이름 -->
            <v-row align="center">
              <v-col cols="3" md="2" class="text-left">
                <h4>이름<span>*</span></h4>
              </v-col>

              <v-col cols="9" md="7">
                <v-text-field
                  v-model="userName"
                  :rules="userNameRule"
                  required
                  outlined
                  dense
                  hide-details
                  maxLength="20"
                />
              </v-col>
            </v-row>

            <!-- 3) 연락처 -->
            <v-row align="center">
              <v-col cols="3" md="2" class="text-left">
                <h4>연락처<span>*</span></h4>
              </v-col>

              <v-col cols="9" md="7">
                <v-text-field
                  v-model="userPhone"
                  :rules="userPhoneRule"
                  required
                  outlined
                  dense
                  hide-details
                  maxLength="11"
                  @keyup="removeDash(userPhone)"
                />
              </v-col>
            </v-row>

            <!-- 4) 이메일 -->
            <v-row align="center">
              <v-col cols="3" md="2" class="text-left">
                <h4>이메일<span>*</span></h4>
              </v-col>

              <v-col cols="9" md="7">
                <v-text-field
                  v-model="userEmail"
                  :rules="userEmailRule"
                  required
                  outlined
                  dense
                  hide-details
                  maxLength="20"
                />
              </v-col>
            </v-row>

            <!-- 5) 내용 -->
            <v-row class="mb-4">
              <v-col cols="3" md="2" class="text-left">
                <h4>내용<span>*</span></h4>
              </v-col>

              <v-col cols="9" md="7">
                <v-textarea
                  v-model="simpleContent"
                  :rules="simpleContentRule"
                  required
                  outlined
                  dense
                  hide-details
                  maxLength="120"
                  no-resize
                />
              </v-col>
            </v-row>

            <!-- EmailJS 폼 -->
            <v-row v-show="false">
              <v-col cols="12">
                <form
                  ref="emailJsForm"
                  name="emailJsForm"
                  @submit.prevent="sendEmail()"
                >
                  <!-- 회사명 -->
                  <div>
                    <label>회사명:</label>
                    <input type="text" name="companyName" value="ssup" />
                  </div>

                  <!-- 이름 -->
                  <div>
                    <label>이름:</label>
                    <input type="text" name="userName" />
                  </div>

                  <!-- 연락처 -->
                  <div>
                    <label>연락처:</label>
                    <input type="text" name="userPhone" />
                  </div>

                  <!-- 이메일 -->
                  <div>
                    <label>이메일:</label>
                    <input type="text" name="userEmail" />
                  </div>

                  <!-- 내용 -->
                  <div>
                    <label>내용:</label>
                    <input type="text" name="simpleContent" />
                  </div>

                  <!-- 버튼 -->
                  <input type="submit" value="Send" />
                </form>
              </v-col>
            </v-row>

            <!-- 6) 문의하기 -->
            <v-row>
              <v-col cols="12" md="9">
                <v-btn
                  color="primary"
                  dark
                  block
                  height="44"
                  class="contact-us-btn"
                  @click="clickContactUs()"
                >
                  <h3>문의하기</h3>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser'

export default {
  data: () => ({
    companyName: '',
    userName: '',
    userNameRule: [v => !!v],
    userPhone: '',
    userPhoneRule: [v => !!v],
    userEmail: '',
    userEmailRule: [v => !!v, v => /.+@.+\..+/.test(v)],
    simpleContent: '',
    simpleContentRule: [v => !!v]
  }),
  methods: {
    removeDash(val) {
      const removed = val.replace(/[^0-9]/g, '')
      this.userPhone = removed
    },
    clickContactUs() {
      this.sendEmail()
    },
    sendEmail() {
      const isValid = this.$refs.contactUsForm.validate()

      if (!isValid) {
        alert('입력을 확인해 주세요.')

        return
      }

      this.$store.commit('toggleLoading', true)

      document.emailJsForm.companyName.value = this.companyName
      document.emailJsForm.userName.value = this.userName
      document.emailJsForm.userPhone.value = this.userPhone
      document.emailJsForm.userEmail.value = this.userEmail
      document.emailJsForm.simpleContent.value = this.simpleContent

      emailjs
        .sendForm(
          'service_e7h44s4',
          'template_q43rerp',
          this.$refs.emailJsForm,
          '8ktsEM0xiqjVsyj8z'
        )
        .then(res => {
          this.$store.commit('toggleLoading', false)
          console.log(res)

          alert('문의가 완료되었습니다. 담당자가 상담 예정입니다.')
          this.companyName = ''
          this.userName = ''
          this.userPhone = ''
          this.userEmail = ''
          this.simpleContent = ''
          this.$refs.contactUsForm.reset()
          this.$vuetify.goTo(0)
        })
        .catch(err => {
          this.$store.commit('toggleLoading', false)
          console.log(err)
          throw err
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#contact-us {
  background-color: $color-bg-gray;
  height: 80vh;

  // 좌
  .left {
    h2 {
      font-size: 48px;
    }

    p {
      font-size: 24px;
    }
  }

  // 우
  .right {
    h4 {
      span {
        margin-left: 2px;
        color: #ef4f00;
        font-weight: 800;
      }
    }
  }

  &.mobile {
    height: 100%;
    padding: 40px 0;

    // 좌
    .left {
      h2 {
        font-size: 24px;
        font-weight: 800 !important;
      }

      p {
        font-size: 12px;
      }
    }

    // 우
    .right {
      h4 {
        font-size: 14px;
      }
    }
  }
}
</style>
